<template>
    <admin-dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col>
                        {{vacancy.job_title}}
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.vacancies.index'}">return</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="vacancy.employer_name" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="vacancy.created_at" disabled></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </admin-dashboard-layout>
</template>

<script>
    import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
    import Vacancy from "@/models/Vacancy";

    export default {
        name: "users-show",
        components: {AdminDashboardLayout},
        data: function () {
            return {
                vacancy: {}
            }
        },
        async mounted() {
            const vacancyId = this.$route.params.id
            this.vacancy = await Vacancy.find(vacancyId)
        },
    }
</script>

<style scoped>

</style>
